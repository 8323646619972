import { Component, LoaderManager } from 'shimmer'
import { AmbientLight, DirectionalLight, Color, Raycaster, Vector3, TextureLoader, PlaneGeometry, MeshBasicMaterial, Mesh } from 'three'
import data from '@/assets/data.json'

import gsap from 'gsap'

const raycast = new Raycaster()

class Clermont extends Component {
  constructor() {
    super("Clermont")

    this.loadObject = this.loadObject.bind(this)
    this.compassTexture = new TextureLoader().load('/images/compass.png')

    this.addLights()
    this.addCompass()
  }

  coordsToPosition(coords) {
    // formatted as long, lat (x, y)
    // bottom left, bottom right, top right, top left
    const geoBoundingBox = data.mapBoundingBox
    const threeBoundingBox = this.regionMesh.geometry.boundingBox

    const xRatio = (coords.lng - geoBoundingBox[0][0]) / (geoBoundingBox[1][0] - geoBoundingBox[0][0])
    const zRatio = (coords.lat - geoBoundingBox[1][1]) / (geoBoundingBox[2][1] - geoBoundingBox[1][1])
    const x = threeBoundingBox.min.x + (threeBoundingBox.max.x - threeBoundingBox.min.x) * xRatio
    const z = threeBoundingBox.max.z - (threeBoundingBox.max.z - threeBoundingBox.min.z) * zRatio

    return this.getComputeFullPosition({ x, z })
  }

  getComputeFullPosition ({x, z}) {
    const basePos = new Vector3(x, -1, z)

    raycast.set(new Vector3(x, 30, z), new Vector3(0, -1, 0))
    const intersections = raycast.intersectObject(this.reliefHit, false)

    if (intersections.length) {
      const res = intersections[0].point.clone()
      return res
    }
    else {
      return new Vector3(x, .15, z)
    }
  }

  async loadObject() {
    return new Promise(async resolve => {
      const obj = await LoaderManager.load('clermont-relief', false)
      this.object = obj[0].object
      this.add(this.object)
      // this.object.rotation.x = Math.PI / 2
      this.object.rotation.y = -0.05
      this.object.traverse(child => {
        if (child.isMesh) {
          child.receiveShadow = true
        }
      })
      this.regionMesh = this.object.getObjectByName('map')
      this.regionMesh.geometry.computeBoundingBox()

      this.reliefHit = this.object.getObjectByName('relief_colision_')
      this.reliefHit.visible = false

      resolve()
    })
  }

  addLights () {
    const ambient = new AmbientLight(0xffffff, .8)
    this.add(ambient)

    // const directional = new DirectionalLight(0xffffff, 0.5)
    // directional.castShadow = true
    // directional.shadow.mapSize.width = 1024
    // directional.shadow.mapSize.height = 1024
    // directional.shadow.camera.near = -10
    // directional.shadow.camera.far = 10
    // directional.shadow.camera.left = -4 
    // directional.shadow.camera.right = 4 
    // directional.shadow.camera.top = 4 
    // directional.shadow.camera.bottom = -4 
    // directional.shadow.bias = 0.0001
    // directional.shadow.radius = 2
  }

  addCompass () {
    const geometry = new PlaneGeometry( 2, 2 )
    const material = new MeshBasicMaterial({
      map: this.compassTexture,
      transparent: true,
      depthWrite: false,
      depthTest: false
    })
    this.compass = new Mesh(geometry, material)
    this.compass.position.set(8, .02, 2)
    this.compass.rotation.x = -Math.PI * .5
    this.add(this.compass)
  }

  set nightMode (value) {
    const color = value ? new Color('#bb9977') : new Color('#ffffff')

    this.traverse(child => {
      if (child.isMesh) {
        // gsap.to{child.material.color}
        gsap.to(child.material.color, {
          r: color.r,
          g: color.g,
          b: color.b,
          duration: 3,
          ease: 'power3.inOut'
        })
      }
    })
  }
}

export const clermont = new Clermont()