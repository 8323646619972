import { CylinderGeometry, CircleGeometry, DoubleSide, FrontSide, RingGeometry, PlaneGeometry, MeshPhongMaterial, MeshBasicMaterial, Color, Mesh, TextureLoader } from 'three'
import { Component } from 'shimmer'

import { clermont } from '@/webGL/objects/Clermont'
import { FadingRing } from '@/webGL/objects/FadingRing'
import { Pin } from '@/webGL/objects/Pin'

import { webGL } from '@/webGL/WebGL'

import gsap from 'gsap'

const height = 1.5

export class Marker extends Component {
  constructor ({coordinates, color, radius, height = 5, onHover = () => {}, onOut = () => {}, image}) {
    super()

    this.alphaMap = new TextureLoader().load('/images/textures/markerAlpha.jpg')
    this.height = .5 + height * .2

    this.createBase(color)

    if (image) {
      this.createMedal(image)
    }
    else {
      this.createRings(radius, color)
      this.createPin(color)
      this.createIntersector()
    }
    this.createShadow()

    this.position.copy(clermont.coordsToPosition(coordinates))
    // glitch to prevent overlap
    this.position.y += Math.random() * .001 
    this.rotation.x = -Math.PI / 2

    this.onHover = onHover
    this.onOut = onOut

    if (this.medal) {
      this.on('enter', this.onHover, this.medal)
      this.on('out', this.onOut, this.medal)
    }
    else {
      this.on('enter', this.onHover, this.intersector)
      this.on('out', this.onOut, this.intersector)
    }
  }

  onUpdate () {
    if (this.medal) {
      this.medal.lookAt(webGL.camera.position.x, 0, webGL.camera.position.z)
    }
  }

  createBase(color) {
    const base = new CircleGeometry(0.08, 20)
    const material = new MeshPhongMaterial({
      color: new Color(color),
      opacity: 1,
      transparent: true,
      depthWrite: false,
      depthTest: false,
      side: FrontSide
    })
    this.baseMesh = new Mesh(base, material)
    this.baseMesh.castShadow = true
    
    this.add(this.baseMesh)
  }

  createRings(radius, color) {
    this.rings = []
    for (let i = 0; i < radius; i++) {
      this.rings.push(new FadingRing(i, color))
    }
    this.add(...this.rings)
  }

  createPin(color) {
    this.pin = new Pin({ color, height: this.height })
    this.pin.threshold = 1
    this.add(this.pin)
  }

  createMedal (url) {
    const texture = new TextureLoader().load(url)
    const geometry = new PlaneGeometry( .3, .3 )
    const material = new MeshBasicMaterial({ map: texture, transparent: true })
    this.medal = new Mesh(geometry, material)
    this.medal.rotation.x = -Math.PI * .5
    this.medal.position.z = .3
    this.add(this.medal)
  }

  createIntersector () {
    const geo = new CylinderGeometry(0.08, 0.08, this.height, 6)
    const material = new MeshBasicMaterial({ transparent: true })
    material.opacity = 0
    this.intersector = new Mesh(geo, material)
    this.intersector.rotation.x = Math.PI * .5
    this.intersector.position.z = this.height * .5
    this.add(this.intersector)
  }

  createShadow() {
    const baseGeo = this.baseMesh.geometry.clone()
    this.baseShadow = new Mesh(baseGeo, new MeshBasicMaterial({ color: 0x000000, opacity: 0.1, transparent: true, side: DoubleSide }))
    this.baseShadow.position.x = -0.02
    this.baseShadow.position.z = -0.02
    this.add(this.baseShadow)

    const shadowGeo = new PlaneGeometry(0.08, 3)
    const shadowMat = new MeshBasicMaterial({ color: 0x000000, opacity: 0.1, transparent: true, side: DoubleSide, alphaMap: this.alphaMap })
    this.shadow = new Mesh(shadowGeo, shadowMat)
    this.shadow.position.x -= 1.5
    this.shadow.position.z -= 0.02
    this.shadow.rotation.z = Math.PI / 2
    this.add(this.shadow)
  }

  set isVisible (value) {
    const duration = 1.5

    if (!value) {
      gsap.fromTo(this.baseShadow.material, {opacity: .1}, { opacity: 0, duration: duration, ease: 'none'})
      gsap.fromTo(this.shadow.material, {opacity: .1}, { opacity: 0, duration: duration, ease: 'none'})
      gsap.fromTo(this.baseMesh.material, {opacity: 1}, { opacity: 0, duration: duration, ease: 'none'})
      gsap.fromTo(this.baseMesh.scale, {x: 1, y: 1, z: 1}, { x: 0, y: 0, z: 0, duration: duration, ease: 'power3.inOut'})

      if (this.medal) {
        gsap.fromTo(this.medal.material, {opacity: 1}, { opacity: 0, duration: duration, ease: 'none'})
        gsap.fromTo(this.medal.scale, {x: 1, y: 1, z: 1}, { x: 0, y: 0, z: 0, duration: duration, ease: 'power3.inOut'})
      }
      else {
        gsap.fromTo(this.pin, {threshold: 1}, { threshold: 0, duration: duration, ease: 'none'})
        this.rings.forEach(ring => ring.isVisible = value)
      }
    }
    else {
      gsap.fromTo(this.baseShadow.material, {opacity: 0}, { opacity: .1, duration: duration, ease: 'none'})
      gsap.fromTo(this.shadow.material, {opacity: 0}, { opacity: .1, duration: duration, ease: 'none'})
      gsap.fromTo(this.baseMesh.material, {opacity: 0}, { opacity: 1, duration: duration, ease: 'none'})
      gsap.fromTo(this.baseMesh.scale, {x: 0, y: 0, z: 0}, { x: 1, y: 1, z: 1, duration: duration, ease: 'power3.inOut'})

      if (this.medal) {
        gsap.fromTo(this.medal.material, {opacity: 0}, { opacity: 1, duration: duration, ease: 'none'})
        gsap.fromTo(this.medal.scale, {x: 0, y: 0, z: 0}, { x: 1, y: 1, z: 1, duration: duration, ease: 'power3.inOut'})
      }
      else {
        gsap.fromTo(this.pin, {threshold: 0}, { threshold: 1, duration: duration, ease: 'none'})
        this.rings.forEach(ring => ring.isVisible = value)
      }
    }
  }
}