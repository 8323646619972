<template>
  <div>
    <div class="u-row u-center">
      <div
        v-for="category in categories"
        @click="$emit('toggle', category.id)"
        class="categories__cat u-w1of12 u-cursor-pointer u-pad-xs"
        :class="{'is-active': selected.length && selected.includes(category.id)}"
      >
        <div class="u-center u-row categories__cat__img">
          <app-image v-if="category.image.length" class="u-w8of12" :class="{'u-w8of12--sm': !noTitle}" :id="category.image[0].id" />
        </div>
        <div v-if="!noTitle" class="t-h4 u-black u-center u-marg-t-sm u-uppercase u-white u-antialiased u-pad-x-xs">
          {{ category.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppImage from '@/components/common/AppImage'

export default {
  emits: ['toggle'],

  props: {
    selected: {
      type: Array,
      default: () => { return [] }
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AppImage
  },

  computed: {
    categories () {
      return this.$store.getters['data/categories']
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.categories
  &__cat
    opacity .5
    transition opacity .3s linear
    
    &.is-active
      opacity 1
      
    &__img
      transform scale(1, 1) translateZ(0)
      transition transform .3s ease-in-out-quad
    &:hover &__img
      transform scale(1.1, 1.1) translateZ(0)
      
</style>