<template>
  <div ref="info" class="info u-white u-pointer-none">
    <div class="info__wrapper">
      <template v-if="data.type === 'members'">
        <div class="lh0">
          <div class="t-h2 u-inline-block" :style="{backgroundColor: data.category?.color}">
            {{ data.title }}
          </div>
        </div>
        <div>
          <div class="t-h3 u-inline-block" :style="{backgroundColor: data.category?.color}">
            {{ data.category.title }}
            <app-image :id="data.category.image[0].id" class="info__image u-inline-block"/>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="lh0">
          <div class="t-h2 u-inline-block" :style="{backgroundColor: data.category?.color}">
            {{ data.title }}
          </div>
        </div>
        <div>
          <div class="t-h3 u-inline-block" :style="{backgroundColor: data.category?.color}">
            {{ data.category.title }}
            <app-image v-if="data.category.image.length" :id="data.category.image[0].id" class="info__image u-inline-block"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'
import AppImage from '@/components/common/AppImage'

export default {
  components: {
    AppImage
  },

  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  mixins: [Object2d],

  created () {
    this._camera = webGL.camera
  },
}
</script>

<style lang="stylus">
.info
  &__wrapper
    // transform translateX(50%)
  
  &__image
    width 1em
    height 1em

.lh0
  line-height 0
</style>