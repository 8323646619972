import { Component } from 'shimmer'

import { CylinderGeometry, ShaderMaterial, Mesh, LessEqualDepth, DoubleSide, Color } from 'three'

import vertexShader from '@/webGL/shaders/fade.vert.js'
import fragmentShader from '@/webGL/shaders/fade.frag.js'

export class Pin extends Component {
  constructor ({ color, height = 1.5 }) {
    super()

    const rgbColor = new Color(color)

    this._threshold = 0

    this.uniforms = {
      uThreshold: { value: 0 },
      color: { value: rgbColor }
    }

    this.geometry = new CylinderGeometry(0.02, 0.02, height, 8, 1, true)

    this.material = new ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      // faudra probablement virer ça avec le reflief
      depthWrite: false,
      depthTest: false,
      depthFunc: LessEqualDepth,
      uniforms: this.uniforms
    })
    this.material.transparent = true
    this.material.side = DoubleSide

    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.position.z += height * .5
    this.mesh.rotation.x = Math.PI * .5
    this.add(this.mesh)
  }

  set threshold (value) {
    this._threshold = value
    this.uniforms.uThreshold.value = 1 - this._threshold
  }

  get threshold () {
    return this._threshold
  }
}