import { createWebHistory } from 'vue-router'

import Homepage from '@/pages/Homepage'
import messages from '@/locales'

const routes = [
  {
    path: '',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: true
    }
  }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

langRoutes.push({
  path: '/:pathMatch(.*)*',
  redirect: { name: 'Homepage' }
})

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
