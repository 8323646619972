
<template>
  <button
    class="t-button u-cursor-pointer u-center t-text"
    :class="{
      'is-white': isWhite,
      'is-ghost': isGhost,
      'is-small': isSmall
    }"
  >
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" :href="url" target="_blank" class="u-relative">
        <slot/>
      </a>
      <router-link v-else :to="url" class="u-relative">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" :to="to" class="u-relative">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="{'u-flex u-center u-middle': icon}">
      <slot/>
    </span>
  </button>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.t-button
  position relative
  padding: clamp(12px, .9em, 20px) clamp(15px, 2em, 40px)
  color: $colors.black
  background-color transparent
  z-index 0
  
  &.is-small
    padding: clamp(5px, .5em, 10px) clamp(10px, 1.5em, 20px) clamp(7px, .7em, 12px)

  &:after
    content ''
    z-index -1
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color: $colors.white
    transition: transform .5s ease-in-out-quart

  &.is-white
    color: $colors.white

    &:after
      background-color: $colors.black
  
  &.is-ghost.is-white
    color: $colors.black

    &:after
      border: solid 3px $colors.black

  &.is-ghost
    color: $colors.white

    &:after
      border: solid 2px $colors.white
      background-color: transparent
  
  &:hover
    &:after
      transform scale(1.2, 1.2)

</style>