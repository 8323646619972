<template>
  <div class="introduction u-wrapper-panel u-frame">
    <div class="u-full-height u-full-width u-row u-center u-middle u-relative">
      <img src="/images/logo.svg" class="u-w2of8" alt="Clermont Ferrand Capiale de la culture">
    </div>
    <div class="introduction__button u-center u-z-middle">
      <app-button @click="$emit('close')">
        <span class="t-h1 u-uppercase">
          <div class="u-inline-block u-marg-r-md">
            Continuer
          </div>
          <svg class="introduction__button__svg u-inline-block u-stroke-black" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-right"/>
          </svg>
        </span>
      </app-button>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'

export default {
  components: {
    AppButton
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.introduction
  &:before
    content ''
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    opacity .5
    background-color: $colors.white
  
  &__button
    position absolute
    bottom $spacings.md
    left 0
    width 100%
    
    &__svg
      width 1em
      height 1em
</style>