<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="icon-diamond" viewBox="0 0 9 11">
          <title>Icon Diamond</title>
          <path d="M0.864193 5.13432L3.6476 10.2801C4.11175 11.1382 5.34304 11.1382 5.80719 10.2801L8.5906 5.13432C8.84803 4.65839 8.7635 4.07003 8.3825 3.68582L5.59909 0.87902C5.11885 0.394745 4.33594 0.394745 3.8557 0.87902L1.07229 3.68582C0.691284 4.07003 0.606757 4.65839 0.864193 5.13432Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 36 36">
          <title>Arrow right</title>
          <path d="M17.8877 2.0332L34.4223 18.0167"  stroke-width="2" stroke-linecap="square"/>
          <path d="M17.8877 34.0002L34.4223 18.0168"  stroke-width="2" stroke-linecap="square"/>
          <path d="M1.07764 18.0168L34.1469 18.0168"  stroke-width="2" stroke-linecap="square"/>
        </symbol>
        
        <!-- <svg preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#arrow-left"/>
        </svg> -->
      </defs>
    </svg>
  </div>
</template>