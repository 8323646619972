<template>
  <transition name="t-fader">
    <info v-if="isHover" :position="position" :data="data" />
  </transition>
</template>

<script>
import { webGL } from '@/webGL/WebGL'
import { Marker } from "@/webGL/objects/Marker"

import Info from "@/components/3d/Info"

export default {
  components: {
    Info
  },

  data() {
    return {
      isHover: false,
      position: {x: 0, y:0, z:0}
    }
  },

  props: {
    data: {
      type: Object,
      default: {}
    },
    coordinates: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  },

  mounted() {
    this.initMarker()
    this.marker.isVisible = true
  },

  methods: {
    initMarker() {
      this.marker = new Marker({
        coordinates: this.coordinates,
        color: this.color,
        radius: this.size,
        onHover: this.onHover,
        onOut: this.onOut,
        height: this.data.clermontHeight ? parseInt(this.data.clermontHeight) : 5,
        image: this.data.type === 'members' ? null : this.data.category.image[0].url
      })
      this.position.x = this.marker.position.x
      this.position.y = this.marker.position.y + .7
      this.position.z = this.marker.position.z
      webGL.scene.add(this.marker)
    },

    onHover () {
      this.isHover = true
    },

    onOut () {
      this.isHover = false
    }
  },

  beforeUnmount () {
    this.marker.isVisible = false
    setTimeout(() => {
      this.marker?.traverse(child => {
        child.geometry?.dispose()
        if (Array.isArray(child.material)) {
          child.material.forEach(material => material.texture?.dispose())
          child.material.forEach(material => material.dispose())
        } else {
          child.material?.texture?.dispose()
          child.material?.dispose()
        }
      })
      this.marker.off('hover', () => this.isHover = true)
      this.marker.off('out', () => this.isHover = false)
      this.marker.destroy()
      webGL.scene.remove(this.marker)
    }, 1500)
  }
}
</script>