import { api } from '@/store/interfaces/apiCraft'

const types = {
  members: {
    get: api.getMember,
    handle: 'members',
    category: 'status'
  },
  cultures: {
    get: api.getCulture,
    handle: 'cultures',
    category: 'types'
  }
}

export default {
  namespaced: true,

  state: {
    currentType: types.members,
    types: [],
    status: [],
    members: [],
    cultures: [],
    loaded: false
  },

  getters: {
    global: state => {
      return state.global
    },
    types: state => {
      return state.types
    },
    status: state => {
      return state.status
    },
    members: state => {
      return state.members
    },
    cultures: state => {
      return state.cultures
    },
    entries: state => {
      return state[state.currentType.handle]
    },
    categories: state => {
      return state[state.currentType.category]
    },
    loaded: state => {
      return state.loaded
    },
  },

  actions: {
    async global ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getGlobal()

        request.then(res => {
          commit('types', res.categories.filter(cat => cat.groupHandle === 'clermontType'))
          commit('status', res.categories.filter(cat => cat.groupHandle === 'clermontStatus'))
          commit('loaded', true)
          resolve()
        })
      })
    },

    async entries ({ commit, state }, categories = []) {
      return new Promise(resolve => {
        const { request, cache } = state.currentType.get(categories)
        // cache.then(res => {
        //   if (res !== null) {
        //     commit(state.currentType.handle, res)
        //   }
        // })

        request.then(res => {
          commit(state.currentType.handle, res)
          resolve()
        })
      })
    },

    async members ({ commit, state }, categories = []) {
      return new Promise(resolve => {
        const { request, cache } = api.getMember(categories)
        cache.then(res => {
          if (res !== null) {
            commit('members', res)
          }
        })

        request.then(res => {
          commit('members', res)
        })
      })
    },

    async cultures ({ commit, state }, categories = []) {
      return new Promise(resolve => {
        const { request, cache } = api.getCulture(categories)
        cache.then(res => {
          if (res !== null) {
            commit('cultures', res)
          }
        })

        request.then(res => {
          commit('cultures', res)
        })
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    currentType (state, payload) {
      state.currentType = types[payload]
    },
    global (state, payload) {
      state.global = payload
    },
    types (state, payload) {
      state.types = payload
    },
    status (state, payload) {
      state.status = payload
    },
    members (state, payload) {
      payload.forEach(entry => {
        entry.category = entry.clermontStatus.length ? entry.clermontStatus[0] : null
        entry.type = 'members'
      })
      state.members = payload
    },
    cultures (state, payload) {
      payload.forEach(entry => {
        entry.category = entry.clermontType.length ? entry.clermontType[0] : null
        entry.type = 'cultures'
      })
      state.cultures = payload
    }
  }
}