export default /* glsl */`
uniform vec2 uResolution;
uniform float uThreshold;

varying vec2 vUv;

void main(void) {
  vec4 mvPosition = modelViewMatrix * vec4( position, 1. );

  vUv = uv;

  gl_Position = projectionMatrix * mvPosition;
}
`