<template>
  <div class="u-wrapper-panel u-grab">
    <div ref="orbit" class="u-wrapper-panel" />
    <transition name="t-fader">
      <introduction v-if="isIntroduction" @close="isIntroduction = false" />
    </transition>
    <transition name="t-fader">
      <categories
        v-show="!isTransition && !isIntroduction"
        :selected="selectedCategories"
        @toggle="onToggleCategory"
        class="home__categories"
      />
    </transition>
    <div class="markers"  v-if="!isTransition && !isIntroduction" >
      <template v-for="entry in entries" :key="entry.id">
        <app-marker
          v-if="entry.category"
          :data="entry"
          :coordinates="entry.geolocalisation"
          :size="parseInt(entry.clermontElipseSize || 1)"
          :color="entry.category ? entry.category.color : 'white'"
        />
      </template>
    </div>
    <transition name="t-fader">
      <type-switch v-show="!isIntroduction" @type="onTypeChange" :currentType="currentType" />
    </transition>
    <transition name="t-fader">
      <population v-show="!isIntroduction && population !== 0 && !isTransition" :population="population" />
    </transition>
  </div>
</template>

<script>
import Categories from '@/components/Categories'
import Introduction from '@/components/Introduction'
import AppMarker from '@/components/3d/Marker'
import Population from '@/components/Population'

import { clermont } from '@/webGL/objects/Clermont'

import TypeSwitch from '@/components/TypeSwitch'

import { webGL } from '@/webGL/WebGL'

import { OrbitControls } from 'shimmer'

export default {
  components: {
    Categories,
    Introduction,
    AppMarker,
    TypeSwitch,
    Population
  },

  data () {
    return {
      currentType: 'members',
      isIntroduction: true,
      selectedCategories: [],
      isTransition: true
    }
  },

  computed: {
    categories () {
      return this.$store.getters['data/categories']
    },
    entries () {
      return this.$store.getters['data/entries']
    },
    population () {
      if (this.currentType === 'members') {
        const entries = this.$store.getters['data/entries']
        return entries.reduce((acc, entry) => {        
          return acc + entry.population
        }, 0)
      }

      return 0
    },
  },

  created () {
    this.onInit()
  },

  mounted  () {
    webGL.camera.position.set(4, 4, 4)

    this.onCategoriesChange()

    this.controls = new OrbitControls(webGL.camera, this.$refs.orbit)
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.05
    this.controls.enablePan = false
    this.controls.maxPolarAngle = Math.PI * .5 - Math.PI * .1
    this.controls.minDistance = 1
    this.controls.maxDistance = 20
    this.$refs.orbit.addEventListener('pointerdown', this.controls.onPointerDown)
    this.$refs.orbit.addEventListener('pointercancel', this.controls.onPointerCancel)
  },

  methods: {
    onTypeChange (slug) {
      if (slug === 'cultures') {
        clermont.nightMode = true
      }
      else {
        clermont.nightMode = false
      }
      this.isTransition = true
      setTimeout(() => {
        this.currentType = slug
      }, 1500)
    },

    onToggleCategory (id) {
      const index = this.selectedCategories.indexOf(id)
      if (index === -1) {
        this.selectedCategories.push(id)
      }
      else {
        this.selectedCategories.splice(index, 1)
      }

      this.$store.dispatch('data/entries', this.selectedCategories)
    },

    onInit () {
      this.$store.commit('data/currentType', this.currentType)
      this.$store.dispatch('data/entries').then(() => {
        this.isTransition = false
      })
    },

    onUpdate (time) {
      if (this.controls) {
        this.controls.update()
      }
    },

    onCategoriesChange () {
      this.selectedCategories.splice(0, this.selectedCategories.length)

      this.categories.forEach((cat) => {
        this.selectedCategories.push(cat.id)
      })
    }
  },

  watch: {
    currentType () {
      this.onInit()
    },
    categories () {
      this.onCategoriesChange()
    }
  },

  beforeUnmount () {
    this.controls.dispose()

    this.$refs.orbit.removeEventListener('pointerdown', this.controls.onPointerDown)
    this.$refs.orbit.removeEventListener('pointercancel', this.controls.onPointerCancel)
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.home
  &__categories
    position absolute
    bottom $spacings.md
    left 10%
    width 80%

    +mq($until: 'tablet')
      right $spacings.md
      width auto
</style>