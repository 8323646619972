import { RingGeometry, MeshPhongMaterial, MeshBasicMaterial, Color, Mesh, FrontSide } from 'three'
import { Component } from 'shimmer'

import gsap from 'gsap'

export class FadingRing extends Component {
  constructor (size, color) {
    super()
    // smoothen steps scale ratio
    // size /= 12
    // size = Math.log((size+1)*1.5) / 2
    size = .12 + size * .05
    this.geometry = new RingGeometry(size, size * 1.1, 32)
    this.material = new MeshPhongMaterial({
      color: new Color(color),
      opacity: 0,
      transparent: true,
      depthWrite: false,
      depthTest: false,
      side: FrontSide
    })
    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.scale.set(0, 0, 0)

    this.animate()
    this.add(this.mesh)
  }

  animate () {
    this.opacityTween = gsap.fromTo(this.mesh.material, { opacity: 1 },{ opacity: 0, duration: 2, ease: 'power1.out'})
    this.scaleTween = gsap.fromTo(this.mesh.scale, {x: 0, y: 0, z: 0},{ x: 1.1, y: 1.1, z: 1.1, duration: 2, ease: 'power1.out', onComplete: () =>{
      if (this._isVisible) {
        this.animate()
      }
    }})
  }

  createShadow () {
    this.shadow = new Mesh(this.geometry, new MeshBasicMaterial({ color: 0x000000, opacity: 0.5, transparent: true, side: 2 }))
    this.shadow.position.y = -0.02
    this.add(this.shadow)
  }

  set isVisible (value) {
    this._isVisible = value
    if (value) {
      this.animate()
    }
  }
}