<template>
  <div class="type-switch">
    <div
      v-for="type in types"
      @click="$emit('type', type.slug)"
      class="type-switch__item u-inline-block u-white t-h2 u-pad-xs t-regular u-cursor-pointer"
      :class="{'is-active': currentType === type.slug}"
      :style="{backgroundColor: type.color}"
    >
      {{ type.name }}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      types: [
        {
          slug: 'members',
          name: 'Adhésion',
          color: '#007A33'
        },
        {
          slug: 'cultures',
          name: 'Culture',
          color: '#FF5E15'
        }
      ]
    }
  },

  props: {
    currentType: {
      type: String,
      default: 'members'
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.type-switch
  position absolute
  top: $spacings.md
  left: $spacings.md

  &__item
    opacity .5
    transition opacity .3s linear
    
    &.is-active
      opacity 1
</style>