<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component :is="Component" v-if="isSceneLoaded && isDataLaoded" />
      </transition>
    </router-view>

    <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden u-pointer-none"/>
    <img alt="Vue logo" src="./assets/logo.png">
    <transition name="t-fader">
      <div v-if="!isSceneLoaded || !isDataLaoded" class="u-wrapper-panel u-bg-white u-z-front"/>
    </transition>

  </div>
</template>

<script>

import App from './App'

import { webGL } from '@/webGL/WebGL'

import SymbolsSvg from '@/components/SymbolsSvg'

import { clermont } from '@/webGL/objects/Clermont'

import {
  SphereBufferGeometry,
  MeshNormalMaterial,
  Mesh
} from 'three'

export default {
  name: 'App',

  mixins: [App],

  data () {
    return {
      isSceneLoaded: false
    }
  },

  computed: {
    isDataLaoded () {
      return this.$store.getters['data/loaded']
    }
  },

  components: {
    SymbolsSvg
  },

  created () {
    this.pointerevent = null
    this.$store.dispatch('data/global')
  },

  mounted () {
    clermont.loadObject().then(() => {
      this.isSceneLoaded = true
    })
    webGL.camera.position.set(1, 1, 20)
    webGL.scene.add(clermont)

    this.onResize()
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>