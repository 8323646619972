<template>
  <div class="u-absolute u-top-md u-right-md t-h2 population">
    Population : {{ formatNumber(population) }}
  </div>
</template>

<script>
export default {
  props: {
    population: {
      type: Number,
      default: 0
    }
  },

  methods: {
    formatNumber (number) {
      number = number.toFixed(2) + '';
      let x = number.split('.');
      let x1 = x[0]
      let rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
      }
      return x1
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.population
  top: $spacings.md
  right: $spacings.md
</style>