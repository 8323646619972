export default /* glsl */`
uniform vec2 uResolution;
uniform float uThreshold;
uniform vec3 color;

varying vec2 vUv;

void main( void ) {
  gl_FragColor = vec4( color.rgb, (1. - vUv.y - uThreshold) * .5 );
}
`